"use client";

import type { ListItemResponse } from "@/data/__generated__/types/generated";

import api from "@/data/api";

import View from "./View";

type ProductsWithoutCollectionProps = {
	categoryDescription?: null | string;
	categoryLft: number;
	categoryRgt: number;
	categoryTitle?: string;
	ssrProducts: ListItemResponse[];
};

const ProductsWithoutCollection: React.FC<ProductsWithoutCollectionProps> = ({
	ssrProducts,
	categoryLft,
	categoryRgt,
	categoryDescription,
	categoryTitle,
}) => {
	const { data, loading, fetchMore } = api.products.CategoryProducts(
		{
			input: {
				categoryLft,
				categoryRgt,
				limit: 45,
				offset: 0,
			},
		},
		{ notifyOnNetworkStatusChange: true }
	);

	const handleFetchMore = () => {
		const nextPage = data?.categoryItems.nextPage;

		if (!nextPage) return;

		fetchMore({
			variables: {
				input: {
					categoryLft,
					categoryRgt,
					limit: 45,
					offset: nextPage,
				},
			},
		});
	};
	return (
		<View
			products={data?.categoryItems.items || ssrProducts}
			handleFetchMore={handleFetchMore}
			isLoading={loading}
			categoryDescription={categoryDescription}
			categoryTitle={categoryTitle}
			totalItems={0}
		/>
	);
};

export default ProductsWithoutCollection;

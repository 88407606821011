import type { ItemResponse, ListItemResponse } from "@/data/__generated__/types/generated";

import CategoryDescription from "../ItemCollectionList/components/CategoryDescription";

import ProductCard from "@/presentation/components/ProductCard";
import ShowMoreButton from "@/presentation/components/ShowMoreButton";

type ProductsWithoutCollectionViewProps = {
	categoryDescription?: null | string;
	categoryTitle?: string;
	handleFetchMore: () => void;
	isLoading: boolean;
	products: ListItemResponse[];
	totalItems?: number;
};

const ProductsWithoutCollectionView: React.FC<ProductsWithoutCollectionViewProps> = ({
	products,
	handleFetchMore,
	isLoading,
	categoryTitle,
	categoryDescription,
}) => {
	return (
		<div className="mb-[120px]">
			<h1 className="mt-4 mb-8 text-5xl leading-[60px] font-medium sm:text-3xl">{categoryTitle}</h1>
			<div className="grid grid-cols-4 gap-6 sm:grid-cols-2">
				{products?.map((product) => {
					return <ProductCard key={product?.id} product={product as ItemResponse} />;
				})}
			</div>
			<ShowMoreButton onClick={handleFetchMore} className="mt-10" isLoading={isLoading} />
			<CategoryDescription categoryDescription={categoryDescription} />
		</div>
	);
};

export default ProductsWithoutCollectionView;
